/* JOST* */


@font-face {
  font-family: "Jost";
  font-weight: 300;
  font-style: normal;
  src: local('Jost'), url("/assets/font/TrueType/Jost-300-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Jost";
  font-weight: 400;
  font-style: normal;
  src: local('Jost'), url("/assets/font/TrueType/Jost-400-Book.ttf") format("truetype");
  font-display: swap;

}

@font-face {
  font-family: "Jost";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('Jost'), url("/assets/font/TrueType/Jost-500-Medium.ttf") format("truetype");

}

@font-face {
  font-family: "Jost";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local('Jost'), url("/assets/font/TrueType/Jost-600-Semi.ttf") format("truetype");
}


@font-face {
  font-family: "Jost";
  src: url("/assets/font/TrueType/Jost-300-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;

}

@font-face {
  font-family: "Jost";
  src: url("/assets/font/TrueType/Jost-400-BookItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;

}


@font-face {
  font-family: "Jost";
  src: url("/assets/font/TrueType/Jost-600-SemiItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;

}

